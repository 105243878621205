<template>
  <div class="product-preview-box">
    <product-image
      :src="imgUrl"
      img />
    <gw-icon-hover
      class="remove-img"
      icon-name="mdi-delete"
      icon-hover="mdi-delete-empty"
      @click="remove()" />
    <div class="product-preview-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage.vue'

export default {
  components: {
    ProductImage
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    imgUrl () {
      return this.product?.photoUrls[0] || ''
    },
    title () {
      return this.product?.model || ''
    }
  },
  methods: {
    replaceURL (value) {
      return value.replace(process.env.VUE_APP_S3_URL, process.env.VUE_APP_S3_IMGIX)
    },
    remove () {
      this.$emit('on-remove')
    }
  }
}
</script>

<style scoped>
.product-preview-box {
  position: relative;
  height: 100%;
  width: 150px;
  border-radius: 6px;
  cursor: grab;
  margin-right: 20px;
}
.product-preview-box:active {
  cursor: grabbing;
}
.product-preview-box:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transition: background-color .25s;
}
.product-preview-box:hover:before {
  background-color: #b5b5b580;
}
.product-preview-box button.remove-img.v-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.product-preview-box button.remove-img.v-btn:hover {
  color: #F44336 !important;
  caret-color: #F44336 !important;
}
.product-preview-title {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 5;
  color: #fff;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
