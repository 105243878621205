<template>
  <v-row>
    <v-col :cols="12">
      <v-row no-gutters>
        <v-col :cols="3">
          <label> Color : </label>
        </v-col>
        <v-col>
          <gw-treeselect
            v-model="skusInfo.color"
            :options="colors"
            value-format="object"
            :disabled="disabled">
            <template
              slot="option-label"
              slot-scope="{ node }">
              <v-row
                no-gutters
                align="center">
                <div
                  class="color-box"
                  :style="`background: ${node.raw.gradientCss}`">
                </div>
                <label>
                  {{ node.raw.label }}
                </label>
              </v-row>
            </template>
            <template
              slot="value-label"
              slot-scope="{ node }">
              <v-row
                no-gutters
                align="center">
                <div
                  class="color-box"
                  :style="`background: ${node.raw.gradientCss}`">
                </div>
                <label>
                  {{ node.raw.label }}
                </label>
              </v-row>
            </template>
          </gw-treeselect>
          <div id="color-treeselect">
            <gw-text-field
              v-model="colorName"
              :rules="rules.textFill"
              label="Color"
              validate-value />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="12">
      <v-row>
        <v-col :cols="3">
          <label>Size : </label>
        </v-col>
        <v-col class="disabled-label">
          <v-radio-group
            v-model="selectedSizeType"
            class="mt-0 pt-0"
            row
            dense
            :disabled="disabled"
            @change="changeRadio($event)">
            <v-radio
              v-for="(data, key) in sizeData"
              :key="`sizeData-${key}`"
              :label="key"
              :value="key" />
          </v-radio-group>
          <gw-select
            v-model="skusInfo.size"
            :items="sortSize(sizeData[selectedSizeType])"
            :rules="rules.textFill"
            label="Size"
            item-text="name"
            return-object
            dense
            multiple
            chips
            :disabled="disabled" />
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="12">
      <gw-drop-file
        v-model="skusInfo.images"
        :img-aspect-ratio="0.75"
        multiple
        label="Sku Images"
        s3-path="/product-images" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: null
    },
    colors: {
      type: Array,
      default: () => []
    },
    size: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      skusInfo: {},
      sizeData: {},
      selectedSizeType: null,
      rules: {
        textFill: {
          required: true
        },
        arrayFill: {
          required: true
        }
      },
      colorName: null
    }
  },
  watch: {
    skusInfo: {
      handler (val) {
        this.$emit('input', val)
        this.setColorName(val)
      },
      deep: true
    },
    value (val) {
      this.skusInfo = val
    },
    size (val) {
      this.getSize(val)
    }
  },
  created () {
    this.skusInfo = this.value
    this.getSize(this.size)
    this.selectedSizeType = this.skusInfo.size && this.skusInfo.size[0] ? this.skusInfo.size[0].type : Object.keys(this.sizeData)[0]
  },
  methods: {
    async setColorName (val) {
      this.colorName = val.color && val.color.name ? val.color.name : null
    },
    getSize (data) {
      for (let i = 0; i < data.length; i++) {
        const size = data[i]
        if (!this.sizeData[size.type]) {
          this.sizeData[size.type] = []
        }
        this.sizeData[size.type].push({
          id: size.id,
          type: size.type,
          name: size.name
        })
      }
    },
    changeRadio () {
      delete this.skusInfo.size
    },
    sortSize (sizes) {
      return sizes.sort((a, b) => a.id - b.id)
    }
  }
}
</script>

<style lang="scss">
#color-treeselect .v-input__slot {
  display: none;
}
.color-box {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0 5px;
  width: 20px;
  height: 20px;
}
</style>
