<template>
  <v-data-table
    v-model="productSelected"
    :items="items"
    item-key="id"
    show-select
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :headers="headers"
    :loading="loading"
    :footer-props="dataTableFooter"
    loading-text="กรุณารอสักครู่...">
    <template #[`item.image`]="{ item }">
      <product-image :src="imageCompute(item)" />
    </template>
  </v-data-table>
</template>

<script>
import ProductImage from '@/components/ProductImage.vue'

export default {
  components: {
    ProductImage
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      productSelected: [],
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
        showCurrentPage: true
      },
      headers: [
        {
          text: '',
          align: 'center',
          value: 'select',
          width: '3%'
        },
        {
          text: 'Image',
          align: 'center',
          value: 'image'
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Model',
          align: 'center',
          value: 'model'
        },
        {
          text: 'Categories',
          align: 'center',
          value: 'categories'
        },
        {
          text: 'Price',
          align: 'center',
          value: 'price'
        }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (_value) {
        const value = { ..._value, page: _value.page || 1 }
        this.$emit('input', value)
      }
    }
  },
  watch: {
    productSelected: {
      handler () {
        this.returnSelectedProduct()
      },
      deep: true
    }
  },
  methods: {
    imageCompute (item) {
      return item.photoUrls?.length > 0 ? item.photoUrls[0] : null
    },
    returnSelectedProduct () {
      this.$emit('on-select', this.productSelected)
    }
  }
}
</script>
