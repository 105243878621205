<template>
  <v-dialog
    v-model="dialog"
    class="product-modal-wrap"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-top-transition">
    <v-card class="product-modal-card">
      <v-toolbar
        dark
        class="product-table-toolbar"
        color="primary">
        <v-btn
          icon
          dark
          @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Product List</v-toolbar-title>
        <v-spacer />
        <v-row>
          <v-col cols="6" />
          <v-col
            cols="6"
            class="pt-8">
            <v-text-field
              v-model="searchText"
              dark
              dense
              placeholder="search"
              outlined
              append-icon="mdi-magnify" />
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container
        class="products-list-page"
        fluid>
        <product-table
          v-model="query"
          :items="products"
          :loading="tableLoading"
          @on-select="onSelectProduct($event)" />
        <v-btn
          fab
          dark
          large
          color="success"
          class="save-btn"
          title="SAVE PRODUCT"
          @click="onSave()">
          <v-icon>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import ProductTable from './ProductTable.vue'

const ProductService = new ProductProvider()

export default {
  components: {
    ProductTable
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      products: [],
      productsSelected: [],
      tableLoading: false,
      searchText: '',
      searchTimeout: null,
      query: {
        search: '',
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true],
        tagOperation: 'OR'
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.fetchProduct()
      },
      deep: true
    },
    searchText () {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.query.search = this.searchText
        this.fetchProduct()
      }, 1000)
    }
  },
  created () {
    this.fetchProduct()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setMember: 'Member/setMember'
    }),
    async fetchProduct () {
      try {
        this.tableLoading = true
        const { data } = await ProductService.getProductsByPaginate(this.query)
        this.query.pages = data.pages
        this.query.total = data.total
        this.products = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[fetch-product-error]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    onSelectProduct (products) {
      this.productsSelected = products
    },
    onSave () {
      this.$emit('on-save', this.productsSelected)
    },
    closeModal () {
      this.dialog = false
    }
  }
}
</script>

<style>
.products-list-page {
  padding-top: 74px;
}
.product-modal-wrap {
  width: 80%;
}
.product-modal-card {
  width: 100%;
}
.product-table-toolbar {
  position: fixed;
  width: 100%;
  z-index: 10;
}
.save-btn {
  position: fixed;
  z-index: 999;
  bottom: 7%;
  right: 3%;
}
</style>
